<template>
  
<div id="kt_body"  class="header-fixed header-tablet-and-mobile-fixed toolbar-enabled">
  <router-view/>
</div>

</template>
<script setup>
/* eslint-disable */
import { useadminUsersStore } from "@/store/adminUsers.store";
const {ref,onMounted}=require("@vue/runtime-core");
const display = ref(false)
const roles = useadminUsersStore();

function getCookie(cname) {
  let name = cname + "=";
  let ca = document.cookie.split(';');
  for(let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}
  setTimeout(()=>{
    let language = getCookie('googtrans')
    if(language=='/en/ar'){
 let ele= document.getElementsByTagName('body')
 ele[0].classList.add("arabic");
 }  else{
  let ele= document.getElementsByTagName('body')
   ele[0].classList.remove("arabic");
 }
  },500)
  
  onMounted(()=>{
    roles.getMenuByRoleWise()

  })
</script>
<style>
 @import 'assets/css/custom.css';
 @import 'assets/css/style.bundle.css';
 @import url('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.0.0-beta3/css/all.min.css');

 .arabic #app{
  text-align: right;
 }
 .arabic #app .company_name{
    justify-content: flex-end;
 }

</style> 
